<template>
  <div class="wrapper">
    <input class="hidden-input" required :value="value" />
    <component :is="'GDropdown'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-dropdown">
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </component>
  </div>
</template>

<script>
import { GDropdown } from '@twentyfourg/grimoire';

export default {
  name: 'PassportDropdown',
  inheritAttrs: false,
  extends: GDropdown,
  components: {
    GDropdown,
  },
  props: {
    showLabels: {
      default: false,
    },
    searchable: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  .hidden-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}
.passport-dropdown {
  ::v-deep {
    .multiselect {
      &__placeholder {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        font-size: 14px;
        line-height: 40px;
        color: $primary-color;
      }
      &__select {
        z-index: 1;
        height: 52px;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 18px;
          height: 9px;
          margin: -4px 0 0 -8px;
          background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
          background-size: 18px 9px;
          border: 0;
        }
      }
      &__input {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 40px;
      }
      &__single {
        padding: 0;
        padding-left: 5px;
        margin: 0;
        line-height: 40px;
      }
      &__tags {
        min-height: 52px;
        padding: 5px;
        border-color: #cbcbcb;
        border-radius: 3px;
      }
      &__content-wrapper {
        border-color: #cbcbcb;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &__option {
        &--highlight {
          color: #222;
          background: #fff;
        }
        &--selected {
          &.multiselect__option--highlight {
            background: $light-grey;
          }
        }
      }
    }
  }
}
</style>
